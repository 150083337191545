<template>
  <div class="manure-produce-model">
    <div class="filter-span">
      <span style="color: #4dcbb9; cursor: pointer" @click="checkModule">模型</span>
    </div>
    <div class="table-class">
      <x-table :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @search="search" @page-change="pageChange" @page-size-change="pageSizeChange">
      </x-table>
    </div>

    <Modal v-model="moduleShow" title="粪污量模型" :width="1658" footer-hide>
      <div class="model-box">
        <div class="model-item">
          <div class="title-box">
            <p class="title">固体/液体粪污生产模型</p>
            <span class="iconfont icon">&#xe6e4;</span>
          </div>
          <div class="forecast-box">
            <div class="forecast-item">
              <p class="count">
                {{ forecastToday ? forecastToday.dryManureValue : "--" }}m³
              </p>
              <p class="text">今日预估固体粪污产量</p>
            </div>
            <div class="forecast-item">
              <p class="count">
                {{ forecastToday ? forecastToday.urineValue : "--" }}m³
              </p>
              <p class="text">今日预估液体粪污产量</p>
            </div>
          </div>
          <div class="formula-box">
            <div class="formula-item">
              <span class="result">G</span>
              <span>=</span>
              <span>X</span>
              <span>x</span>
              <span>C</span>
            </div>
            <div class="formula-item">
              <span class="result">L</span>
              <span>=</span>
              <span>Z</span>
              <span>x</span>
              <span>C</span>
            </div>
          </div>
          <div class="annotation-box">
            <div class="annotation-item">
              <p>
                G：今日预估固体粪污产量（<span>{{
                  forecastToday ? forecastToday.dryManureValue : "--"
                }}</span>）
              </p>
              <p>
                X：产污系数（<span>{{
                  forecastToday ? forecastToday.dryManureUnitValue : "--"
                }}</span>）
              </p>
              <p>
                C：存栏数量（<span>{{
                  forecastToday ? forecastToday.num : "--"
                }}</span>）
              </p>
            </div>
            <div class="annotation-item">
              <p>
                L：今日预估液体粪污产量（<span>{{
                  forecastToday ? forecastToday.urineValue : "--"
                }}</span>）
              </p>
              <p>
                Z：产污系数（<span>{{
                  forecastToday ? forecastToday.urineUnitValue : "--"
                }}</span>）
              </p>
              <p>
                C：存栏数量（<span>{{
                  forecastToday ? forecastToday.num : "--"
                }}</span>）
              </p>
            </div>
          </div>
        </div>
        <div class="model-item">
          <div class="title-box">
            <p class="title">氮养分生产模型</p>
            <span class="iconfont icon">&#xe6e4;</span>
          </div>
          <div class="content-box">
            <div class="n-yield-box">
              <p class="n-count">
                {{ forecastToday ? forecastToday.elementAmount : "--" }}kg
              </p>
              <p class="n-text">今日预估氮(N)产量</p>
              <img src="imgs/produceModel/N.png" alt="" />
            </div>
            <div class="formula-and-annotation">
              <div class="formula-box">
                <div class="formula-item">
                  <span class="result">N</span>
                  <span>=</span>
                  <span>C</span>
                  <span>x</span>
                  <span>T</span>
                </div>
              </div>
              <div class="annotation-box">
                <div class="annotation-item">
                  <p>
                    N：今日预估氮(N)产量（<span>{{
                      forecastToday ? forecastToday.elementAmount : "--"
                    }}</span>）
                  </p>
                  <p>
                    C：存栏数量（<span>{{
                      forecastToday ? forecastToday.num : "--"
                    }}</span>）
                  </p>
                  <p>
                    T：猪当量排泄量（<span>{{
                      forecastToday ? forecastToday.elementUnitValue : "--"
                    }}</span>）
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="model-item">
          <div class="title-box">
            <p class="title">鲜粪生产模型</p>
            <span class="iconfont icon">&#xe6e4;</span>
          </div>
          <div class="content-box">
            <div class="n-yield-box">
              <p class="n-count">
                {{ forecastToday ? forecastToday.freshManureValue : "--" }}kg
              </p>
              <p class="n-text">今日预估鲜粪产量</p>
              <img src="imgs/produceModel/xf.png" alt="" />
            </div>
            <div class="formula-and-annotation">
              <div class="formula-box">
                <div class="formula-item">
                  <span class="result">F</span>
                  <span>=</span>
                  <span>Z</span>
                  <span>x</span>
                  <span>D</span>
                </div>
              </div>
              <div class="annotation-box">
                <div class="annotation-item">
                  <p>
                    F：今日预估鲜粪产量（{{
                      forecastToday ? forecastToday.freshManureValue : "--"
                    }}）
                  </p>
                  <p>
                    Z：单位鲜粪产量（{{
                      forecastToday ? forecastToday.freshManureUnitValue : "--"
                    }}）
                  </p>
                  <p>D：动物单位（{{ animalUnit }}）</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import CU from '@/common/util'
export default {
  name: '',
  data() {
    return {
      table: {
        columns: [
          {
            title: '养殖场名称',
            minWidth: 110,
            render: (h, { row }) => {
              return <span>{row.farmName}</span>
            },
          },
          {
            title: '负责人员',
            minWidth: 110,
            key: 'createUserName',
          },
          {
            title: '猪当量',
            width: 100,
            key: 'num',
          },
          {
            title: '存栏量',
            width: 100,
            key: 'clstockNum',
          },
          {
            title: '母猪数量(头)',
            width: 150,
            key: 'sowsNum',
          },
          {
            title: '仔猪数量(头)',
            width: 150,
            key: 'pigletNum',
          },
          {
            title: '固体粪污产量(m³)',
            width: 150,
            key: 'dryManureValueVolume',
          },
          {
            title: '液体粪污产量(m³)',
            width: 150,
            key: 'urineValueVolume',
          },
          {
            title: '记录时间',
            width: 180,
            key: 'createTime',
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 13,
        total: 0,
      },
      search_data: {},
      farmScene: [],
      forecastToday: null,
      currentFarmScene: [],
      moduleShow: false,
    }
  },
  computed: {
    config() {
      let config = {
        // filter: {
        //   width: 200,
        //   filterBox: [
        //     {
        //       conditionName: "养殖区",
        //       component: "cascader",
        //       data: this.farmScene,
        //       field: "area",
        //       defaultValue: [],
        //       // isChangeOnSelect: true
        //     },
        //   ],
        // },
        page: this.page,
      }
      return config
    },
    // 动物单位
    animalUnit() {
      if (
        !this.forecastToday ||
        !this.forecastToday.avgWeight ||
        !this.forecastToday.num
      )
        return '--'
      return (
        (+this.forecastToday.avgWeight * +this.forecastToday.num) /
        1000
      ).toFixed(1)
    },
  },
  watch: {
    currentFarmScene: {
      handler() {
        this.getList()
        // this.getTodayModel();
      },
      immediate: true,
    },
  },
  methods: {
    checkModule() {
      this.moduleShow = true
    },
    search(data) {
      this.page.pageNo = 1
      let n = {
        ...data,
        farmId: data.area.length == 0 ? '' : data.area[0] + '',
        sceneId: data.area.length == 0 ? '' : data.area[1] + '',
      }
      delete n.area
      this.search_data = n
      this.getList()
      this.getTodayModel()
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },

    getList() {
      this.table.loading = true
      let params = {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      }
      this.currentFarmScene.length > 0 &&
        (params.farmId = this.currentFarmScene[0])
      this.currentFarmScene.length > 1 &&
        (params.sceneId = this.currentFarmScene[1])
      this.$post(this.$api.MANURE_PRODUCTION_MODEL.LIST, params)
        .then((res) => {
          this.table.data = res.list
          this.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },
    getTodayModel() {
      if (!this.currentFarmScene || this.currentFarmScene.length < 2) return
      this.$post(this.$api.MANURE_PRODUCTION_MODEL.PRODUCTION, {
        // ...this.search_data,
        farmId: this.currentFarmScene[0] + '',
        sceneId: this.currentFarmScene[1] + '',
      }).then((res) => {
        this.forecastToday = res[0]
      })
    },

    getBreedArea() {
      this.$post(this.$api.PRODUCTION_AREA.FARMSCENE, {
        siteType: '2',
      }).then((res) => {
        let list = CU.formatTreeData(res, 'id', 'name', 'sceneList')
        let currentFarmScene = []
        list.forEach((item) => {
          if (!item.children || item.children.length === 0) item.disabled = true
          else if (
            currentFarmScene.length === 0 &&
            item.children &&
            item.children.length > 0
          ) {
            currentFarmScene = [item.value, item.children[0].value]
          }
        })
        this.farmScene = list
        // this.currentFarmScene = currentFarmScene;
      })
    },
  },
  mounted() {
    this.getList()
    this.getBreedArea()
    // this.getTodayModel();
  },
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>